import L from "leaflet";
import React from "react";
import { LayersControl, Marker, Popup, useMap } from "react-leaflet";
import MarkerClusterGroup from "@changey/react-leaflet-markercluster";
import { defaultIcon } from "../icons/defaulticon";
import { PopupStatistics } from "./marker_layer";
import booleanPointInPolygon from "@turf/boolean-point-in-polygon";

// Component for displaying a layer of markers with clustering and tooltips
export const MarkerLayerWithTooltipCluster = ({
  data,
  getGeoFilter,
  setRadiusFilter,
  getRadiusFilter,
}) => {
  const leafletMap = useMap(); // Hook to access the Leaflet map instance
  const geoFilter = getGeoFilter(); //  to get the current geographic filter
  const radiusFilter = getRadiusFilter(); // to get the current radius filter

  // Computing the center point of the radius filter if it's set
  let centerPoint;
  if (radiusFilter) {
    const { latitude, longitude } = radiusFilter.item.location;
    centerPoint = L.latLng(latitude, longitude);
  }

  // Filtering data based on geographic and radius filters
  const filteredData = data.filter((item) => {
    let filterByGeo = true;
    let filterByRadius = true;

    const { latitude, longitude } = item.location;
    // Checking if item is within the radius filter
    if (centerPoint) {
      const currentPoint = L.latLng(latitude, longitude);
      filterByRadius =
        centerPoint.distanceTo(currentPoint) / 1000 < radiusFilter.radius;
    }

    // Checking if item is within the geographic filter
    if (geoFilter) {
      const point = {
        type: "Feature",
        properties: {},
        geometry: {
          type: "Point",
          coordinates: [parseFloat(longitude), parseFloat(latitude)],
        },
      };
      filterByGeo = booleanPointInPolygon(point, geoFilter);
    }

    return filterByGeo && filterByRadius; // Return items that pass both filters
  });

  // Mapping the filtered data to markers
  const layer = filteredData.map((item) => {
    const { latitude, longitude } = item.location;
    return (
      <Marker
        key={item.id}
        position={[latitude, longitude]}
        icon={defaultIcon}
        eventHandlers={{
          click: (e) => {
            leafletMap.panTo(e.latlng);
          },
        }}
      >
        <Popup className="popup-custom">
          <PopupStatistics item={item} setRadiusFilter={setRadiusFilter} />
        </Popup>
      </Marker>
    );
  });

  // Rendering the layer of markers inside a clustering group within the map's layer control
  return (
    <LayersControl.Overlay checked name="Crimes clustered">
      <MarkerClusterGroup zoomToBoundsOnClick={true}>
        {layer}
      </MarkerClusterGroup>
    </LayersControl.Overlay>
  );
};
