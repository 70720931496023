import React, { createContext, useState, useContext } from "react";

// Creating context for managing crime data
const CrimeDataContext = createContext();

// Custom hook to provide access to the CrimeDataContext from any component
export const useCrimeData = () => useContext(CrimeDataContext);

// Provider component to encapsulate and provide the crime data context to children components
export const CrimeDataProvider = ({ children }) => {
  // State hook to store crime data and a setter function to update it
  const [asyncCrimeData, setAsyncCrimeData] = useState([]);

  // Provide the crime data state and the setter function to all children components
  return (
    <CrimeDataContext.Provider value={{ asyncCrimeData, setAsyncCrimeData }}>
      {children}
    </CrimeDataContext.Provider>
  );
};
