import React from "react";
import { Map } from "./Map/Map";
import { CrimeDataProvider } from "./context/data_context";
import DesktopOnly from "./components/desktop_check";

import "leaflet/dist/leaflet.css";
import "leaflet-geosearch/dist/geosearch.css";
import "./App.css";
import "antd/dist/antd.variable.min.css";
import "leaflet.markercluster/dist/MarkerCluster.css";
import "leaflet.markercluster/dist/MarkerCluster.Default.css";

export const App = () => {
  return (
    <CrimeDataProvider>
      <DesktopOnly>
        <Map />
      </DesktopOnly>
    </CrimeDataProvider>
  );
};
