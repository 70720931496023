import { useEffect, useState } from "react";
import { LayersControl, MapContainer, TileLayer } from "react-leaflet";

import { useCrimeData } from "../context/data_context";

import { regions_lad } from "../data/regions_lad";

import { MarkerLayer } from "../layers/marker_layer";
import { RadiusFilter } from "../layers/radius_filter";
import { RegionsPolygonLayer } from "../layers/regions_polygon_layer";
import { MarkerLayerWithTooltipCluster } from "../layers/marker_layer_with_tooltip_cluster";

import { FitBoundsToDataControl } from "../controls/fit_data_to_bounds";
import { ShowActiveFiltersControl } from "../controls/show_active_filters";
import CitySearchField from "../controls/city_search";
import DangerousStreets from "../components/dangerous_streets";
import { InfoBanner } from "../components/info_banner";

import { message } from "antd";

// Main map component
export const Map = () => {
  // State hooks to manage geographic and radius filters
  const [geoFilter, setGeoFilter] = useState(null);
  const getGeoFilter = () => geoFilter;
  const [radiusFilter, setRadiusFilter] = useState(null);
  const getRadiusFilter = () => radiusFilter;

  const { asyncCrimeData, setAsyncCrimeData } = useCrimeData(); // Using context to access crime data

  const position = [50.91159, -1.40749]; // Default position for Southampton

  // Effect hook to fetch crime data based on a geographical polygon area that is created from coordinates and chosen offset
  useEffect(() => {
    const latOffset = 0.19; // this sets how large area will be fetched
    const lngOffset = latOffset / Math.cos(position[0] * (Math.PI / 180));
    const coordinates = [
      [position[0] - latOffset, position[1] - lngOffset],
      [position[0] - latOffset, position[1] + lngOffset],
      [position[0] + latOffset, position[1] + lngOffset],
      [position[0] + latOffset, position[1] - lngOffset],
    ];

    // Converting the array of coordinates to a string in the format required by the API
    const poly = coordinates.map((coord) => coord.join(",")).join(":");

    // Asynchronous function to fetch and handle crime data
    const fetchData = async () => {
      const hide = message.loading({
        content: "Fetching crime data...",
        duration: 0, // 0 duration so it stays until manually removed
        style: {
          marginTop: "10vh",
        },
      });

      try {
        const response = await fetch(
          `https://data.police.uk/api/crimes-street/all-crime?poly=${poly}`
        );
        const crimeData = await response.json();
        setAsyncCrimeData(crimeData);
        hide(); // removal of the loading message
        message.success({
          content: "Crime data fetched successfully!",
          duration: 2, // lasts 2s
          style: {
            marginTop: "10vh",
          },
        });
      } catch (error) {
        console.error("Failed to fetch crime data:", error);
        hide(); // Ensuring that loading message is removed in case of an error
        message.error({
          content: "Failed to fetch crime data",
          duration: 2,
          style: {
            marginTop: "10vh",
          },
        });
      }
    };
    fetchData().catch(console.error);
  }, []);

  // this section renders the map container and all layers
  return (
    <MapContainer center={position} zoom={13} scrollWheelZoom={true}>
      <LayersControl position="topright">
        <LayersControl.BaseLayer checked name="OSM Streets">
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
        </LayersControl.BaseLayer>
        <LayersControl.BaseLayer name="ESRI World Imagery">
          <TileLayer
            url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
            attribution="Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community"
          />
        </LayersControl.BaseLayer>
        <MarkerLayer
          data={asyncCrimeData}
          setRadiusFilter={setRadiusFilter}
          getRadiusFilter={getRadiusFilter}
          getGeoFilter={getGeoFilter}
        />
        <MarkerLayerWithTooltipCluster
          data={asyncCrimeData}
          setRadiusFilter={setRadiusFilter}
          getRadiusFilter={getRadiusFilter}
          getGeoFilter={getGeoFilter}
        />
        <RadiusFilter
          radiusFilter={radiusFilter}
          setRadiusFilter={setRadiusFilter}
        />
        <RegionsPolygonLayer
          data={regions_lad}
          setGeoFilter={setGeoFilter}
          getGeoFilter={getGeoFilter}
        />
      </LayersControl>
      <FitBoundsToDataControl />
      <ShowActiveFiltersControl
        getFilters={() => ({ geoFilter, radiusFilter })}
      />
      <CitySearchField />
      <DangerousStreets
        crimeData={asyncCrimeData}
        geoFilter={geoFilter}
        radiusFilter={radiusFilter}
      />
      <InfoBanner />
    </MapContainer>
  );
};
