import ReactDOM from "react-dom/client";
import { Button } from "antd";
import { BorderInnerOutlined, BorderOuterOutlined } from "@ant-design/icons";
import L from "leaflet";
import { createControlComponent } from "@react-leaflet/core";
import { unmountComponentAtNode } from "react-dom";

// Creating a new DOM element for the Leaflet control
const node = L.DomUtil.create("div");
// Creating a React root inside this newly created DOM node for rendering
const root = ReactDOM.createRoot(node);

L.Control.FitBoundsToDataControl = L.Control.extend({
  options: {
    position: "topleft",
  },
  onAdd: function (map) {
    // Function to fit the map bounds to all markers on the map
    const doFitDataToBounds = () => {
      const latLngs = [];
      map.eachLayer((layer) => {
        if (layer instanceof L.Marker) {
          const latLng = layer.getLatLng();
          if (latLng) {
            latLngs.push(latLng);
          }
        }
      });
      if (latLngs.length > 0) {
        const bounds = L.latLngBounds(latLngs);
        map.fitBounds(bounds, { padding: [50, 50] });
      }
    };

    // Function to fit the map bounds to the geographical extent of the UK
    const fitBoundsToUK = () => {
      const ukBounds = [
        [49.959999905, -7.57216793459],
        [58.6350001085, 1.68153079591],
      ];
      map.fitBounds(ukBounds);
    };

    // Common properties for the buttons to be rendered in the control
    const commonProps = {
      className: "leaflet-control-layers",
      style: { width: "33px", height: "33px" },
    };

    // Rendering the buttons using ReactDOM inside the Leaflet control
    root.render(
      <div className="fit-bounds-control-container">
        <Button
          {...commonProps}
          title="Fit bounds to data"
          icon={<BorderInnerOutlined />}
          onClick={doFitDataToBounds}
        />
        <Button
          {...commonProps}
          title="Fit bounds to the UK"
          icon={<BorderOuterOutlined />}
          onClick={fitBoundsToUK}
        />
      </div>
    );

    return node;
  },
  onRemove: function (map) {
    unmountComponentAtNode(node);
  },
});

export const FitBoundsToDataControl = createControlComponent(
  (props) => new L.Control.FitBoundsToDataControl(props)
);
