import { useEffect } from "react";
import { useMap } from "react-leaflet";
import { GeoSearchControl, OpenStreetMapProvider } from "leaflet-geosearch";
import { useCrimeData } from "../context/data_context";
import { message } from "antd";

const CitySearchField = () => {
  const map = useMap(); // Hook to get the current map instance
  // custom hook to access the context data
  const { setAsyncCrimeData } = useCrimeData();

  useEffect(() => {
    const provider = new OpenStreetMapProvider();
    const searchControl = new GeoSearchControl({
      provider: provider,
      style: "bar",
      showMarker: false,
    });

    // Event listener for search results
    map.on("geosearch/showlocation", (result) => {
      const latOffset = 0.19;
      const lngOffset =
        latOffset / Math.cos(result.location.y * (Math.PI / 180));

      // Creating a polygon around the search result location to define the area to fetch crime data
      const poly = [
        [result.location.y - latOffset, result.location.x - lngOffset],
        [result.location.y - latOffset, result.location.x + lngOffset],
        [result.location.y + latOffset, result.location.x + lngOffset],
        [result.location.y + latOffset, result.location.x - lngOffset],
      ]
        .map((coord) => coord.join(","))
        .join(":");

      // Function to fetch crime data from an API
      const fetchCrimeData = async () => {
        const hide = message.loading({
          content: "Fetching crime data...",
          duration: 0, // 0 duration so it stays until manually removed
          style: {
            marginTop: "10vh",
          },
        });

        try {
          const response = await fetch(
            `https://data.police.uk/api/crimes-street/all-crime?poly=${poly}`
          );

          if (response.status === 503) {
            hide();
            message.error({
              content:
                "UK Police API does not provide crime data for this location.",
              duration: 3,
              style: { marginTop: "10vh" },
            });
            return;
          }

          const data = await response.json();
          if (data.length === 0) {
            hide();
            message.error({
              content:
                "Crime data is not available for locations outside England.",
              duration: 3,
              style: { marginTop: "10vh" },
            });
            return;
          }
          setAsyncCrimeData(data);
          hide(); // removal of the loading message
          message.success({
            content: "Crime data fetched successfully!",
            duration: 2, // lasts 2s
            style: {
              marginTop: "10vh",
            },
          });
        } catch (error) {
          console.error("Failed to fetch crime data:", error);
          hide(); // Ensuring that loading message is removed in case of an error
          message.error({
            content: "Failed to fetch crime data",
            duration: 2,
            style: {
              marginTop: "10vh",
            },
          });
        }
      };

      fetchCrimeData();
    });

    map.addControl(searchControl); // Adding the search control to the map
    // Cleanup function to remove the search control and event listener
    return () => {
      map.removeControl(searchControl);
      map.off("geosearch/showlocation");
    };
  }, [map, setAsyncCrimeData]); // Dependency array includes map and setAsyncCrimeData to ensure effect runs correctly

  return null;
};

export default CitySearchField; // export the component
