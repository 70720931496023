import { List } from "antd";

export const ShowActiveFiltersControl = ({ getFilters }) => {
  // Retrieve current filters
  const { geoFilter, radiusFilter } = getFilters();

  // Function to format the display of active filters
  const getDisplayFilters = () => {
    const filtersToDisplay = [];

    // Helper function to round numbers for better display
    const round = (num) => Math.round(num * 100) / 100;

    // If there is a geographic filter, add its description to the display list
    if (geoFilter) {
      filtersToDisplay.push(geoFilter.properties.LAD13NM);
    }

    // If there is a radius filter, format its description and add to the display list
    if (radiusFilter) {
      const { latitude, longitude } = radiusFilter.item.location;
      const { radius } = radiusFilter;
      const radiusFilterToDisplay = `
            Center: (Lat: ${round(latitude)}, Lon: ${round(longitude)})
            Radius: ${radius} km`;
      filtersToDisplay.push(radiusFilterToDisplay);
    }

    // if there is either a radius filter or a geofilter, we return filtersToDisplay array,
    // and if there isn't, an array with one String "No Filters Active" is returned
    return filtersToDisplay.length > 0
      ? filtersToDisplay
      : ["No Filter Active"];
  };

  const RenderActiveFilters = () => {
    return (
      <List
        size="small"
        header={
          <div>
            <b>Active Filters</b>
          </div>
        }
        bordered
        dataSource={getDisplayFilters()}
        renderItem={(item) => <List.Item>{item}</List.Item>}
      />
    );
  };

  return (
    <div className="leaflet-bottom leaflet-left">
      <div className="leaflet-control leaflet-bar leaflet-control-layers">
        <RenderActiveFilters />
      </div>
    </div>
  );
};
