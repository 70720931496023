import React, { useState } from "react";
import { Button } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import qrCodeImage from "../images/survey-qr-code.png";

export const InfoBanner = () => {
  const [isHovering, setIsHovering] = useState(false);

  return (
    <div
      className={`info-banner ${isHovering ? "expanded" : "minimized"}`}
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
    >
      {isHovering ? (
        <div className="banner-content">
          <h2>Project Information</h2>
          <p>
            This application is part of a dissertation project titled
            "Development of an Interactive Web Application to Navigate Crime
            Hotspots and Enhance Urban Well-being". It leverages mapping
            technologies to visualize crime data, helping users identify and
            understand crime hotspots within urban environments.
          </p>
          <p>
            Please explore the application and participate in the associated
            survey to provide feedback and contribute to the research.
          </p>
          <p>
            Survey Link:{" "}
            <a
              href="https://app.onlinesurveys.jisc.ac.uk/s/solent/development-of-an-interactive-web-application-to-navigate-crime"
              target="_blank"
              rel="noopener noreferrer"
            >
              Development of an Interactive Web Application Survey
            </a>
          </p>
          <p>Or scan the QR code below to access the survey:</p>
          <img
            src={qrCodeImage}
            alt="Survey QR Code"
            style={{ width: "100px", height: "100px" }}
          />
        </div>
      ) : (
        <Button
          type="default"
          icon={<InfoCircleOutlined />}
          className="info-button"
        />
      )}
    </div>
  );
};
