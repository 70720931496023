import L from "leaflet";
import React, { useState } from "react";
import { LayersControl, LayerGroup, Marker, Popup } from "react-leaflet";
import { defaultIcon } from "../icons/defaulticon";
import { Button, InputNumber, Tooltip, List, Typography } from "antd";
import { FilterOutlined } from "@ant-design/icons";
import booleanPointInPolygon from "@turf/boolean-point-in-polygon";

const { Text } = Typography; // Typography component for text formatting

const DEFAULT_RADIUS = 1; // Default radius value for the filter

// PopupStatistics component for displaying and setting filter radius on crime data points
export const PopupStatistics = ({ item, setRadiusFilter }) => {
  const [radius, setRadius] = useState(DEFAULT_RADIUS); // Local state to manage radius input
  const { name: streetName } = item.location.street; // Destructuring to get the street name from the item
  const { category } = item; // Destructuring to get the category of the incident

  // Rendering a list showing details about the incident and input for setting filter radius
  return (
    <List size="small" bordered>
      <List.Item>
        <Text strong>Street Name:</Text> {streetName}
      </List.Item>
      <List.Item>
        <Text strong>Category:</Text> {category}
      </List.Item>
      <List.Item>
        <Tooltip title="Set the radius in kilometers to filter incidents around this point">
          <InputNumber
            defaultValue={DEFAULT_RADIUS}
            min={1}
            onChange={(value) => setRadius(value)}
            style={{ marginRight: 8 }}
          />
          <Button
            type="primary"
            shape="round"
            icon={<FilterOutlined />}
            onClick={() => setRadiusFilter({ item, radius })}
          >
            Filter by km
          </Button>
        </Tooltip>
      </List.Item>
    </List>
  );
};

// MarkerLayer component to display markers for each crime incident
export const MarkerLayer = ({
  data,
  setRadiusFilter,
  getRadiusFilter,
  getGeoFilter,
}) => {
  const geoFilter = getGeoFilter(); // Get the current geographic filter
  const radiusFilter = getRadiusFilter(); // Get the current radius filter

  let centerPoint; // Variable to store the center point for radius filtering
  if (radiusFilter) {
    const { latitude, longitude } = radiusFilter.item.location;
    centerPoint = L.latLng(latitude, longitude); // Leaflet's LatLng object for the center point
  }

  // Filtering and mapping data to markers
  const layer = data
    .filter((currentItem) => {
      let filterByRadius = true;
      let filterByGeo = true;

      const { latitude, longitude } = currentItem.location;
      if (centerPoint) {
        const currentPoint = L.latLng(latitude, longitude);
        filterByRadius =
          centerPoint.distanceTo(currentPoint) / 1000 < radiusFilter.radius;
      }

      if (geoFilter) {
        const point = {
          type: "Feature",
          properties: {},
          geometry: {
            type: "Point",
            coordinates: [parseFloat(longitude), parseFloat(latitude)],
          },
        };
        filterByGeo = booleanPointInPolygon(point, geoFilter);
      }

      return filterByRadius && filterByGeo;
    })
    .map((item) => {
      const { latitude, longitude } = item.location;
      const position = [parseFloat(latitude), parseFloat(longitude)];

      return (
        <Marker key={item.id} position={position} icon={defaultIcon}>
          <Popup>
            <PopupStatistics item={item} setRadiusFilter={setRadiusFilter} />
          </Popup>
        </Marker>
      );
    });

  // Rendering the layer group containing all markers within the LayersControl.Overlay
  return (
    <LayersControl.Overlay name="Crime incidents">
      <LayerGroup>{layer}</LayerGroup>
    </LayersControl.Overlay>
  );
};
