import React, { useEffect, useState, useMemo } from "react";
import { List, Button } from "antd";
import { DownOutlined, UpOutlined } from "@ant-design/icons";
import { debounce } from "lodash";
import booleanPointInPolygon from "@turf/boolean-point-in-polygon";
import L from "leaflet";
import { point as turfPoint } from "@turf/turf";

const DangerousStreets = ({ crimeData, geoFilter, radiusFilter }) => {
  const [topStreets, setTopStreets] = useState([]); // State for storing the list of top dangerous streets
  const [isVisible, setIsVisible] = useState(false); // State to toggle visibility of the list

  // useMemo to calculate top streets based on crime data and current filters
  const computeTopStreets = useMemo(() => {
    const streetCount = {}; // Object to count occurrences per street

    // Filter the data based on used filters
    const filteredData = crimeData.filter((crime) => {
      const latitude = parseFloat(crime.location.latitude);
      const longitude = parseFloat(crime.location.longitude);

      // Checking for valid coordinates
      if (isNaN(latitude) || isNaN(longitude)) {
        console.error(
          "Invalid coordinates:",
          crime.location.latitude,
          crime.location.longitude
        );
        return false;
      }

      // Checking if crime is within the geographical filter
      let inGeo = !geoFilter;
      if (geoFilter) {
        const crimePoint = turfPoint([longitude, latitude]);
        inGeo = booleanPointInPolygon(crimePoint, geoFilter);
      }

      // Checking if crime is within the radius filter
      let inRadius = !radiusFilter;
      if (radiusFilter) {
        const centerLatitude = parseFloat(radiusFilter.item.location.latitude);
        const centerLongitude = parseFloat(
          radiusFilter.item.location.longitude
        );
        if (
          !isNaN(centerLatitude) &&
          !isNaN(centerLongitude) &&
          !isNaN(radiusFilter.radius)
        ) {
          const centerPoint = L.latLng(centerLatitude, centerLongitude);
          const crimePoint = L.latLng(latitude, longitude);
          const distance = centerPoint.distanceTo(crimePoint) / 1000;
          inRadius = distance <= radiusFilter.radius;
        }
      }

      return inGeo && inRadius;
    });

    // Counting incidents of each street in filtered data by how many times they occured
    filteredData.forEach((crime) => {
      const streetName = crime.location.street.name;
      if (streetName) {
        streetCount[streetName] = (streetCount[streetName] || 0) + 1;
      }
    });

    // Sorting and picking top 5 streets with the highest crime count
    return Object.entries(streetCount)
      .sort((a, b) => b[1] - a[1])
      .slice(0, 5)
      .map(([name, count]) => ({ name, count }));
  }, [crimeData, geoFilter, radiusFilter]);

  // Update of topStreets state when computeTopStreets changes
  useEffect(() => {
    setTopStreets(computeTopStreets);
  }, [computeTopStreets]);

  // Function to toggle the visibility of the street list, debounced to avoid rapid state changes
  const toggleVisibility = debounce(() => {
    setIsVisible((prev) => !prev);
  }, 300);

  // render
  return (
    <div className="dangerous-streets-container">
      <div className="header-container">
        <span>High crime locations in this area</span>
        <Button
          type="primary"
          onClick={toggleVisibility}
          icon={isVisible ? <DownOutlined /> : <UpOutlined />}
          size="small"
          style={{ marginLeft: "8px" }}
          aria-expanded={isVisible}
          aria-label={
            isVisible
              ? "Hide details about high crime locations"
              : "Show details about high crime locations"
          }
        >
          {isVisible ? "Hide" : "Show"}
        </Button>
      </div>
      {isVisible && (
        <List
          size="small"
          bordered
          dataSource={topStreets}
          renderItem={(item) => (
            <List.Item>
              {item.name}: {item.count} incidents
            </List.Item>
          )}
          style={{ marginTop: "8px" }}
        />
      )}
    </div>
  );
};

export default DangerousStreets;
