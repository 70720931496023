import { LayersControl, GeoJSON } from "react-leaflet";

// Definition of a functional component to render polygon layers on the map
export const RegionsPolygonLayer = ({ data, setGeoFilter, getGeoFilter }) => {
  // Retrieve the current geographic filter setting using the getGeoFilter function
  const geoFilter = getGeoFilter();
  // Definition of a layer using GeoJSON for rendering geographic data
  const layer = (
    <GeoJSON
      key="geo-json-layer"
      data={data}
      eventHandlers={{
        click: (e) =>
          setGeoFilter((prevState) => {
            const same = prevState === e.propagatedFrom.feature;
            return same ? null : e.propagatedFrom.feature;
          }),
      }}
      style={(feature) => {
        return {
          color: geoFilter === feature ? "red" : "rgb(8, 143, 143)", // if geoFilter equals current feature, it returns red, if not >> blue
          weight: 0.5,
          fillOpacity: 0.4,
        };
      }}
    ></GeoJSON>
  );
  // Wraping the GeoJSON layer inside a LayerControl.Overlay to enable toggling in the UI
  return <LayersControl.Overlay name="Regions">{layer}</LayersControl.Overlay>;
};
