import { LayersControl, Circle } from "react-leaflet";
import { useEffect, useState } from "react";
import { message } from "antd";

// Definition of a functional component to manage the radius filter on a map
export const RadiusFilter = ({ radiusFilter, setRadiusFilter }) => {
  // State to control the visibility of a popup message
  const [showPopup, setShowPopup] = useState(false);

  // Effect hook to handle showing and configuring a popup message
  useEffect(() => {
    // This useEffect will only run when showPopup changes to true
    if (showPopup) {
      message.config({
        top: `15%`,
        duration: 3,
        style: { marginTop: "-20px" },
      });

      message.info(
        "Double-click on the grey circle area to remove the filter.",
        3
      );
      const timer = setTimeout(() => {
        setShowPopup(false); // hides the popup
        message.config({
          top: 24,
          duration: 3,
          style: {},
        });
      }, 4000);

      // Cleanup function to clear the timer and reset message config
      return () => {
        clearTimeout(timer);
        // to ensure the message configuration is reset when the component is unmounted or updated
        message.config({
          top: 24,
          duration: 3,
          style: {},
        });
      };
    }
  }, [showPopup]); // Dependency array including showPopup

  // Effect hook to trigger popup when radiusFilter is set
  useEffect(() => {
    // Showing popup when a radiusFilter is active
    if (radiusFilter) {
      setShowPopup(true);
    }
  }, [radiusFilter]); // Dependency on radiusFilter

  // Rendering the Circle layer if a radiusFilter exists
  if (radiusFilter) {
    const { latitude, longitude } = radiusFilter.item.location;
    const layer = (
      <Circle
        center={[latitude, longitude]}
        radius={radiusFilter.radius * 1000} // Converts to km
        eventHandlers={{
          dblclick: (e) => {
            e.originalEvent.view.L.DomEvent.stopPropagation(e); // Prevent zoom on double-click
            setRadiusFilter(null); // Remove the radius filter on double-click
          },
        }}
        color={"gray"}
        weight={1}
        fillOpacity={0.4}
      />
    );

    // Returning the Circle wrapped in a LayersControl.Overlay for toggling on the map
    return (
      <LayersControl.Overlay checked name="Radius filter">
        {layer}
      </LayersControl.Overlay>
    );
  } else {
    return null; // Return null if no radiusFilter is set
  }
};
